
.FifthPage>.section {
  height: 100% !important;
}
.FifthPage {
  background-color: #f1f1fb;
  height: 100%;
  width: 100%;
  padding-block: 3.333vw;
  font-family: 'Poppins';
font-style: normal;
}
.cardView {
  width: 100%;
  height: max-content;
  background: #ffffff;
  box-shadow: 0px 4px 25px 4px rgba(0, 0, 0, 0.12);
}

.cardView>h1{
  font-size: 1.5em;
}
.cards {
  width: 80%;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
}
.hcard {
  width: 28%;
  max-width: 356px;
  height: max-content;
  
}
/* .mobileView .hcard {
  max-width: 80%;
  margin-inline: auto !important;
  display: block !important;
  height: max-content;
  
} */
.cardView:hover {
  background-color: rgba(211, 211, 211, 0.767);
}

.cardView:hover .cardNew {
  background-color: #11373e;
  color: white;
}
.cardTopPos {
  display: flex;
  justify-content: end;
  /* margin: -35px 0px; */
}

.cardNew {
  background-color: #11373e28;
  /* opacity: 0.2; */
  padding: 5px 15px;
  width: min-content;
  font-weight: bold;
  height: max-content;
}

.desktopView .hc0{
  padding-bottom: 20vh;
}
.desktopView .hc1{
  padding-block: 10vh;
}
.desktopView .hc2{
  padding-top: 20vh;
}
.cardView .fa, .cardView .fas{
  border: 1px dashed #11373E;
  border-radius: 50%;
  padding: 10px;
  color: black;
  font-size: 18px;
  margin: 0 !important;
}

.mobileView{
  display: none;
}
.desktopView{
  display: flex;
}

@media only screen and (min-width: 768px) {
  
  .desktopView{
    display: flex;
  }
  .mobileView{
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .FifthPage {
    height: 100%;
    padding-block: 20px;
    padding-inline: 3.5rem;
  }
  .desktopView{
    display: none;
  }
  .mobileView{
    display: block;
  }
  .slick-slide{
    display: flex !important;
    justify-content: center;
  }
  .hcard {
    width: 100%;
    min-width: 356px;
    padding-block: 20px;
  }
  .cardView{
    width: 100%;
    min-width: 356px;
    margin-inline: auto;
  }
}

@media only screen and (max-width: 480px) {
  .FifthPage {
    height: 100%;
    padding-block: 20px;
    padding-inline: 0;
  }
  
  .cards{
    width: 100%;
  }
  .desktopView{
    display: none;
  }
  .mobileView{
    display: block;
  }
  .slick-slide{
    display: flex !important;
    justify-content: center;
  }
  .hcard {
    width: 100%;
    min-width: 156px;
    padding-block: 20px;
  }
  .cardView{
    width: 90%;
    min-width: 156px;
    margin-inline: auto;
  }
  .cardView>h1{
    font-size: 1.125em;
  }
  .cardView .fa, .cardView .fas{
    padding: 7px;
    font-size: 16px;
  }
}

