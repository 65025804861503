.sec6 {
  background-color: #f1f1fb;
  height: 100vh;
  width: 100%;
  padding-block: 8.333vw;
  font-family: 'Poppins';
font-style: normal;
}
.SixthPage {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-inline: auto;
}
.SixthPage > div {
  /* width: 50%; */
}
.SixthPage >.leftSide {
  width: 40vw;
  display: flex;
  justify-content: start;
}
.SixthPage >.leftSide > img {
  max-width: 100%;
  max-height: 100%;
  /* margin-block: 32px; */
  margin-right: -6.33vw;
}
.SixthPage>.RightSide{
  display: block;
  width: 60vw;
  overflow: hidden;
  margin-top: 20px;
  padding-inline: 2.5em;
  padding-block: 1.5em;
}
.SixthPage > .RightSide > h1 {
  font-size: 2em;
  animation: left_to_right 3s ease;
  text-transform: capitalize;
  padding-left: 2rem;
  width: 100%;
}
.SixthPage > .RightSide > ul {
  display: flex;
  justify-content: space-between;
  padding: 0;
  animation: bottom_to_top 3s ease;
  padding-left: 30px;
}

.fa,
.fab {
  cursor: pointer;
  height: 40px;
  width: 40px;
  text-align: center;
  text-decoration: none;
  margin: 5px 0;
}

.fa:hover,
.fab:hover {
  opacity: 0.7;
}
.fa-facebook-f {
  padding: 10px;
  font-size: 34px;

  border-radius: 50%;
  background: #3b5998;
  color: white;
}
.fa-youtube-play {
  font-size: 40px;
  color: #bb0000;
}
.fa-instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );

  padding: 3px;
  font-size: 34px;

  border-radius: 16px/12px;
  color: white;
}
.fa-telegram {
  font-size: 40px;

  border-radius: 50%;
  background: white;
  color: #229ed9;
}
.fa-discord {
  padding: 6px 2px;
  font-size: 26px;

  border-radius: 50%;
  background: #5865f2;
  color: white;
}

/* @media only screen and (max-width: 768px) {
  
    margin-right: -6.33vw;
  }
  .fa,
  .fab {
    margin: 5px 0.65rem;
  }
} */

@media only screen and (max-width: 768px) {
  .sec6{
    height: 100vh !important;
    padding-inline: 3.5rem;
  }
  .SixthPage {
    width: 100% !important;
    flex-direction: column;
  }
  .SixthPage>.leftSide{
    width: 100% !important;
    height: 80%;
    display: flex;
  justify-content: center;
  }
  .SixthPage >.leftSide > img {
    width: 100%;
    max-height: 100%;
  }
  .SixthPage > div {
    width: 100%;
    /* margin-inline: auto; */
    text-align: center;
  }
  .SixthPage > .RightSide{
    width: 100%;
  }
  .SixthPage > .RightSide > h1 {
    font-size: 2em;
    animation: left_to_right 3s ease;
    text-transform: capitalize;
    /* padding-left: 2rem; */
    width: 100%;
  }

}


@media only screen and (max-width: 480px) {
  .sec6{
    padding-inline: 1.5rem !important;
    padding-top: 106px;
    height: 100% !important;

  }
  .sec6 .RightSide{
    width: 100%;
    padding: 0px;
  }
  .SixthPage > .RightSide > h1 {
    font-size: 1em;
    width: 100%;
    padding: 0;
    font-style: 600;
    line-height: 24px;

  }
  .SixthPage > .RightSide > ul {
    margin-inline: auto;
  }
  .fa,
  .fab {
    height: 32px;
    width: 32px;
  }

  .fa-facebook-f {
    padding: 10px;
    font-size: 22px;
  }
  .fa-youtube-play {
    font-size: 36px;
  }
  .fa-instagram {

    padding: 2px 3px;
    font-size: 27px;
  
    border-radius: 8px;
  }
  .fa-telegram {
    font-size: 32px;
  }
  .fa-discord {
    padding: 6px 2px;
    font-size: 20px;
  }

}

