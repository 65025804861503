.FourthPage {
  background-color: #f1f1fb;
  font-family: 'Poppins';
  font-style: normal;
  
}


.videoSlider {
  background: transparent;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding-block: 8.333vw;
  /* position: relative; */
  margin-inline: auto !important;
}

.Videoslide1 {
  /* width: 80%;
  height: 100%;
  text-align: center;
  margin-inline: auto;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2); */
  position: relative;
  padding-bottom: 45%;
  height: 0;
  /* overflow: hidden; */
  width: 80%;
  margin-inline: auto !important;
}

.videoSlider>h1{
  position: absolute;
  margin-inline: auto;
  width: 80%;
  /* margin-block: 0; */
  /* margin-top: -5.25%; */
}

.Videoslide1>iframe {
  /* min-height: 100% !important; */
  /* width: 100% !important; */
  /* height: 100% !important; */
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
  .videoSlider {
    padding-block: 120px;
    padding-inline: 3.5rem;
    
    width: 100%;
  }
  .Videoslide1 {
    /* width: 80%;
    height: 100%;
    text-align: center;
    margin-inline: auto;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.2); */
    position: relative;
    padding-bottom: 45%;
    height: 0;
    /* overflow: hidden; */
    width: 80%;
    margin-inline: auto !important;
  }
  .videoSlider>h1{
    position: absolute;
    margin-inline: auto;
    bottom: 60%;

    /* width: 80%; */
    /* margin-block: 0; */
    /* margin-top: -5.25%; */
  }
  .videoSlider>div{
    overflow-y: unset !important;
    height: 45% !important;
  }
  .videoSlider>div>div{
    height: min-content;
  }


  .Videoslide1 {
    
    width: 100%;
  }

  .videoSlider  .PrevButton,
  .videoSlider .NextButton {
  cursor: pointer;
  /* background-color: red !important; */
  /* opacity: 0.5 !important; */
  color: transparent !important;
  font-weight: bold !important;
  /* padding: 10px; */
  border-radius: 50%;
  border-color: none;
  border-width: 0;
  z-index: 1 !important;
}

.videoSlider .PrevButton {
  /* all: unset; */
  left: 35% !important;
  top: 90% !important;
  position: absolute;
  background-image: url("../Assets/left_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;
  
}

.videoSlider .NextButton {
  width: 40px;
  height: 40px;
  transform: translateX(-50%);
  right: 35% !important;
  top: 90% !important;
  position: absolute;
  background-image: url("../Assets/right_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

  
}

}

@media only screen and (max-width: 480px) {
  .slidShowContainer{
    height: min-content;
  }
  .videoSlider{
    height: fit-content;
    z-index: 1;
    padding-block: 64px;
    width: 100%;
    padding: 0 1.75em;
  }
  .videoSlider>h1{
    position: absolute;
    margin-inline: auto;
    top: 45%;
    font-size: 1em;
  }
  .videoSlider>div{
    overflow-y: unset !important;
    min-height: 356px !important;
  }

  .videoSlider .PrevButton {
    /* all: unset; */
    left: 25% !important;
    top: 65% !important;
    position: absolute;
    background-image: url("../Assets/left_arrow.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 24px;
    height: 24px;
    z-index: 1;
    
  }
  
  .videoSlider .NextButton {
    width: 24px;
    height: 24px;
    transform: translateX(-50%);
    right: 25% !important;
    top: 65% !important;
    position: absolute;
    background-image: url("../Assets/right_arrow.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: transparent;
  
    
  }

}