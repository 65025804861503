body {
  background-color: #f1f1fb;
  /* overflow: hidden; */
}
.container {
  width: 100vw;
  height: 100vh;
  /* overflow-x: hidden; */
}
.section {
  height: 100vh !important;
  padding-top: 106px;
}

@keyframes left_to_right {
  from {
    margin-left: -120%;
  }
  to {
    margin-left: 0vw;
  }
}

@keyframes bottom_to_top {
  from {
    margin-top: 80px;
  }
  to {
    margin-top: 0%;
  }
}
@media only screen and (max-width: 768px) {
  .section {
    height: 100% !important;
    padding-top: 20px;
    
  }
}
