.cl1 {
  background: linear-gradient(180deg, #11373e 0%, rgba(17, 55, 62, 0) 97.12%);
}

.main {
  display: flex;
  justify-content: space-between;
  padding: 60px 8.333vw 0;
  line-height: 1.5;
  font-family: 'Poppins';
font-style: normal;
height: calc(100vh - 106px);

}
.mainLeft {
  padding-top: 3.5em;
  width: 55vw;
  color: white;
  height: 100%;
}
.mainRight {
  width: 45vw;
  display: flex;
  /* justify-content: end; */
}

.mainRight > img {
  max-width: 100%;
  max-height: 100%;
  margin-block: 2em;
  margin-right: -6.33vw;
}
.mainLeft > h1 {
  line-height: 1.25;
  color: white;
  width: 100%;
  font-size: 2.625em;
  padding: 0;
  margin: 0;
  animation: left_to_right 3s ease;
}

.mainLeft > p {
  font-size: 1em;
  animation: left_to_right 2.5s ease;
}

.mainLeft > div > .download {
  cursor: pointer;
  background: #fd6e43;
  border: 1.5px solid #fd6e43;
  border-radius: 184px;
  min-width: 204px;
  height: 48px;
  font-weight: 500;
  font-size: 20px;
  /* line-height: 30px; */
  color: white;
  text-align: center;
  animation: bottom_to_top 3s ease;
}

.mainLeft > div {
  height: 80px;
  padding-top: 20px;
  overflow: hidden;
}
.mainLeft>div>.download>span>.fa{
  color: #fd6e43;
  background: white ;
  border-radius: 50%;
  height: 1em;
  width: 1em;
margin: 0;

}
/* .mainLeft > div > .download::after {
  font-size: 1em  !important;
  display: inline-block;
  content: "\2192";
  width: 22px;
  height: 22px;
  margin: 2px 0px 0px 10px;
  border-radius: 45px /42px;
  border: 1.5px solid #ffffff;
} */
.mainLeft > div > .tutbtn {
  cursor: pointer;
  width: 187px;
  height: 48px;
  border: 1.5px solid #ffffff;
  border-radius: 184px;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  background-color: transparent;
  color: #ffffff;
  margin-left: 20px;
  animation: bottom_to_top 2.5s ease;
}

@media only screen and (max-width: 868px) {
  .main {
    padding-inline: 3.5rem;
    line-height: 1.25;
    /* text-align: center; */
    margin-inline: auto;
    width: 100%;
  }
  .marginLeft {
    width: 65vw;
  }
  
  .mainRight {
    width: 35vw;
  }
  .mainRight > img {
    margin-right: -1.5vw;
  }
  .mainLeft > h1 {
    /* font-size: 1.25em; */
  }
}


@media only screen and (max-width: 480px) {
  .main{
    height: 100vh;
    padding: 1.25em;
    line-height: 1;
  }
  .mainLeft{
    padding-top: 0;
  }
  .mainLeft > div {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: unset;
    line-height: 1;
  }
  .mainLeft > div > .download {
    border-radius: 84px;
    width: 133px;
    height: 39px;
    font-weight: 400;
    font-size: 16px;
    padding: 0;
  }
  .mainLeft > div > .tutbtn {
    
    width: 87px;
    height: 48px;
    
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    
    margin-left: 20px;
    
  }
  /* .download{
    min-width: 187px !important;
  height: 48px;
  font-weight: 500;
  font-size: 20px;
  }
  .tutbtn{
    margin-top: 20px;
  } */
  .mainLeft > h1 {
    font-size: 1.5em;
    line-height: 36px;
  }
  .mainLeft > p {
    line-height: 24px;
  }

}


@media only screen and (max-width: 768px) {
  .main {
    /* flex-wrap: wrap; */
    flex-direction: column-reverse;
    
    height: 100vh;
  }
  .mainLeft {
    width: 100%;
    height: 65vh;
    padding-top: 0;
  }
  .mainRight {
    width: 100%;
    justify-content: center;
    height: 45vh;
  }
  .mainRight > img {
    text-align: center;
    margin-inline: center;
  }

  .container {
    /* height: 100%; */
  }
  .section {
    padding-top: 20px;
    /* height: 100%; */
  }
  .mainLeft > div > .download {
    background: #fd6e43;
    border: 1.5px solid #fd6e43;
    border-radius: 184px;
    min-width: 150px;
    height: 48px;
    font-weight: 500;
    font-size: 1.25em;
    /* line-height: 30px; */
    color: white;
    text-align: center;
    animation: bottom_to_top 3s ease;
  }
  .mainLeft > div > .tutbtn {
    min-width: 150px;
    height: 48px;
    border: 1.5px solid #ffffff;
    border-radius: 184px;
    font-weight: 500;
    font-size: 1.25em;
    line-height: 1.5;
    /* identical to box height */
    background-color: transparent;
    color: #ffffff;
    margin-left: 2%;
    animation: bottom_to_top 2.5s ease;
  }
}
