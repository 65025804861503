.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 8.333vw;
  background: linear-gradient(180deg, #11373e 0%, rgba(17, 55, 62, 0) 107.93%);
  height: 106px;
  z-index: 1;
  overflow-x: hidden;
  font-family: 'Poppins';
font-style: normal;

}

.headerLeft {
  
  display: flex;
  width: 50vw;
  justify-content: start;
  text-align: center;
  height: 100%;
}

.headerLeft > .logo {
  cursor: pointer;
  padding: 10px;
  width: 47px;
  height: 47px;
}

.headerLeft > h1 {
  cursor: pointer;
  color: white;
  padding: 8px 12px;
  margin-inline: 12px;
  font-size: 2.5em;
  margin-block: 0;
  text-transform: capitalize;
}

.headerRight {
  width: 50vw;
  display: flex;
  justify-content: end;
}

.headerRight > div {
  justify-content: end;
  height: min-content;
  text-align: center;
  padding-block: 20px;
  font-size: 16px;
  width: 100%;
}
.headerMenuTool {
  display: none;
}
.fa-bars {
  position: absolute;
  right: 10px;
  font-weight: bold;
  font-size: 2em;
  color: white;
}

.headerRight > div > select {
  cursor: pointer;
  /* -webkit-appearance: none; */
  color: white;
  background: none;
  padding: 5px 2px;
  border: none;
  font-size: 16px;
  margin: 0 5px;
}

.headerRight > div > select option {
  background-color: #ffffff;
  color: black;
  padding: 5px;
}

.headerRight > div > .contactUs {
  cursor: pointer;
  background: transparent;
  padding: 5px 12px;
  border: none;
  color: white;
  font-size: 16px;
  margin-inline: 10px;
  min-width: 110px;
}
.headerRight > div > .download {
  cursor: pointer;
  min-width: 150px;
  background: #fd6e43;
  border: none;
  color: white;
  font-size: 16px;
}
.popup {
  padding: 20px 2.5vw;
}
.headerContent1 {
  display: flex;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  width: 25%;
}
.headerContent1 > select {
  cursor: pointer;
  color: white;
  background: none;
  padding: 5px 2px;
  border: none;
  font-size: 16px;
  margin: 20px 2.5vw;
  width: 100px;
}
.header img{
  width: 100%;
  height: 100%;
}
.headerContent1 > select option {
    background-color: #ffffff;
    color: black;
    padding: 5px;
  }

.headerContent1 > .contactUs {
  cursor: pointer;
  background: transparent;
  padding: 5px 2px;
  border: none;
  color: white;
  font-size: 16px;
  margin-inline: 2.5vw;
  min-width: 110px;
}

@media only screen and (max-width: 868px) {
  .header {
    padding-inline: 2.5vw;
  }
  .headerRight > div > select {
    margin: 0 2px;
    outline: 0;
  }
  .headerRight > div > .contactUs {
    padding-inline: 6px;
    margin-inline: 2px;
    outline: 0;
  }
  .headerRight > div > .download {
    min-width: 150px;
    padding-inline: 2px;
    margin-inline: 0;
    outline: 0;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .headerContent {
    display: none;
  } /* hide it elsewhere */
  .headerMenuTool {
    display: block !important;
  }
}
@media screen and (min-width: 769px) {
  .headerContent {
    display: flex;
  } /* hide it elsewhere */
}
.headerMenuTool {
  display: none;
}

@media only screen and (max-width: 480px) {
  .header{
    height: 64px;
    padding: 22px;
  }
  .headerLeft > .logo {
    padding: 0;
    width: 28px;
    height: 28px;
  }
  .headerLeft > h1 {
    padding: 0px 2px;
    font-size: 1.5em;
  }
  .headerRight > div {
    padding-block: 0px;
  }
  .fa-bars {
    font-size: 1.5em;
    color: white;
  }
 
}
