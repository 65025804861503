.sec3 {
  background-color: #f1f1fb;
  height: 100vh;
  font-family: 'Poppins';
  font-style: normal;
  padding-top: 0 !important;
}

.slidShowContainer {
  height: 100vh;
  width: 80%;
  margin-inline: auto;
}

.slide1 {
  background-color: #ffe7e0;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding-block: 8.333vw;
  position: relative;
}

.slide1>h1 {
  font-size: 2em;
  margin-inline: auto;
}


.slide1>video {
  width: 80%;
  height: 100%;
}

.slide2 {
  background-color: #fbf9d5;
  display: flex;
  height: 100vh !important;
  width: 100%;
  text-align: center;
  padding-block: 8.333vw;
  position: relative;
}

.slide1>div,
.slide2>div {
  height: 100%;
  width: 50%;
  padding: 10px;
}

.slide2>div>img {
  width: 100%;
  overflow: hidden;
}

.slide2>div>h1 {
  font-size: 2em;
  width: max-content;
  margin-inline: auto;
}

.PrevButton,
.NextButton {
  cursor: pointer;
  background-color: transparent !important;
  /* opacity: 0.5 !important; */
  color: transparent !important;
  font-weight: bold !important;
  /* padding: 10px; */
  border-radius: 50%;
  border-color: none;
  border-width: 0;
}

.PrevButton {
  left: 5% !important;
  top: 50%;
  position: absolute;
  background: url("../Assets/left_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 40px;
  height: 40px;

}

.NextButton {
  width: 40px;
  height: 40px;
  transform: translateX(-50%);
  right: 0% !important;
  top: 50%;
  position: absolute;
  background: url("../Assets/right_arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: transparent;

}

.slide3 {
  background-color: #d4f3ff;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding-block: 8.333vw;
  position: relative;
}

.slide3>h1 {
  font-size: 2em;
  margin-inline: auto;
}

.slide3>video {
  width: 80%;
  height: 100%;
}

.slide4 {
  background-color: #f9e4d7;
  height: 100vh;
  width: 100%;
  text-align: center;
  padding-block: 8.333vw;
  position: relative;
}

.slide4>h1 {
  font-size: 2em;
  margin-inline: auto;
}

.slide4>video {
  width: 80%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .sec3 {
    background-color: #e5e5e5;
    padding-block: 20px;
  }

  .slidShowContainer {
    width: 100%;
  }

  .slide1,
  .slide3,
  .slide4 {
    height: 100% !important;
    padding-block: 120px;
    width: 100vw;
    padding-inline: 3.5rem;
    /* margin: 0; */
  }

  .slide3>video,
  .slide4>video {
    width: 100%;
    height: min-content;
  }

  .slide2 {
    /* height: min-content;
    flex-direction: column;
    overflow-y: hidden; */
    padding-block: 120px;
    padding-inline: 3.5rem;
    width: 100vw;
  }

  .slide1>div,
  .slide2>div {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    /* padding: 10px; */
  }

  .slide1>video {
    width: 100%;
    height: fit-content;
  }

  .slide2>div>img {
    height: 100%;
    overflow-y: hidden !important;
  }

  .sec3 .PrevButton,
  .sec3 .NextButton {
    cursor: pointer;
    background-color: transparent !important;
    /* opacity: 0.5 !important; */
    color: transparent !important;
    font-weight: bold !important;
    /* padding: 10px; */
    border-radius: 50%;
    border-color: none;
    border-width: 0;
  }

  .sec3 .PrevButton {
    /* all: unset; */
    left: 35% !important;
    top: 90%;
    position: absolute;
    background: url("../Assets/left_arrow.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;

  }

  .sec3 .NextButton {
    width: 40px;
    height: 40px;
    transform: translateX(-50%);
    right: 35% !important;
    top: 90%;
    position: absolute;
    background: url("../Assets/right_arrow.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: transparent;

  }

}

@media only screen and (max-width: 480px) {
.sec3{height: 100vh;}
  .slide1,
  
  .slide3,
  .slide4 {
    display: flex;
    flex-direction: column;
    padding-inline: 24px;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-block: 64px;
  }
  .slide2{
    padding-inline: 24px;
    height: 100%;
    padding-block: 64px;
  }

  .slide1>h1,
  .slide2>div>h1,
  .slide3>h1,
  .slide4>h1 {
    font-size: 1em;
  }

  .sec3 .PrevButton,
  .sec3 .NextButton {
    width: 24px;
    height: 24px;
    top: 85%;
  }
}