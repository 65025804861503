/* Popup style */
.popup-box {
    position: fixed;
    background: #11373E;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: #11373E;
    border-radius: 4px;
    /* padding: 20px; */
    overflow: auto;
}

.close-icon {
    font-family: Arial, Helvetica, sans-serif;
    content: '"\00d7';
    cursor: pointer;
    position: fixed;
    right: 10px;
    top: 35px;
    background: transparent;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    color: white;
    /* line-height: 20px; */
    text-align: center;
    font-size: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
}



@media screen and (min-width: 0px) and (max-width: 768px) {
    .popup-box {
        display: block !important;
    }
}

@media screen and (min-width: 768px) {

    .popup-box {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .close-icon {
        top: 18px;
        
        width: 24px;
        height: 24px;
        
        font-size: 2em;
        font-weight: 200;
        
        padding: 2px;
    }
    
}