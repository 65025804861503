.sec2 {
  background: url("../Assets/default_bg_1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  font-family: 'Poppins';
font-style: normal;
background-position: center;
}

.SecondPage {
  display: flex;
  justify-content: space-between;
  padding: 60px 8.333vw 0;
  line-height: 1.5;
  height: 100%;
}
.SecLeft {
  width: 50vw;
  color: white;
  height: 100%;
}
.SecRight {
  width: 50vw;
  display: flex;
  justify-content: end;
  padding-block: 60px;
}
.SecLeft > div {
  height: 80%;
  width: 100%;
  margin: 20px;
  line-height: 1;
  
}
.SecLeft > div > img {
  max-width: 100%;
  max-height: 100%;
}
.SecRight>div{
  display: flex;
  flex-direction: column;
  
  /* justify-content: flex-end; */
}
.SecRight>div>h1{
  margin: 0;
  padding: 0;
  font-size: 2em;
  padding-inline-end: 10px;
  animation: bottom_to_top 3s ease;
}
.SecRight>div>p{
  font-size: 1.25em;
  animation: bottom_to_top 3s ease;
}
.SecRight >div >button{
  font-size: 1.5em;
  cursor: pointer;
  padding: 5px;
  border: 3px solid rgb(163, 163, 163);
  color: #a3a3a3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 8px 6px;
}
@media only screen and (max-width: 480px) {
  .SecondPage{
    padding-inline: 25px;
  }
  .sec2{
    height: 100%;
  }
  .SecLeft{
    padding-top: 0;
    height: min-content;
    height: 50vw;
  }
  .mainLeft > div {
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: unset;
  }
  .SecondPage .download{
    min-width: 187px !important;
  height: 48px;
  font-weight: 500;
  font-size: 20px;
  }
  .tutbtn{
    margin-top: 20px;
  }
  .mainLeft > h1 {
    font-size: 1.75em;
  }
}
@media only screen and (max-width: 768px) {

  .SecLeft > div {
    padding-top: 20px;
    width: 100%;
    margin: 20px;
    align-items: center;
    text-align: center;
    align-items: center;

    
  }
  .SecLeft > div > img {
    max-width: 100%;
    max-height: 100%;
    margin-inline: auto;
  }

  .SecondPage {
    flex-direction: column;
    height: 100vh !important;
    justify-content: center;
    padding-block: 20px;
  }
  .SecLeft {
    width: 100%;
    height: 50vh;
  }
  .SecLeft>div{
    height: 40vh;
  }
  .SecLeft > div > img {
    max-width: 100%;
    max-height: 100%;
  }
  .SecRight {
    width: 100%;
    height: 50vh;
    justify-content: start;
    flex-direction: column;
    padding-block: 0;
    line-height: 1;
  }
  .SecRight>.content{
    height: 30vh;
  }
  .SecRight>.button{
    flex-direction: row;
    justify-content: center;
  }

  .SecRight>div>h1{
    font-size: 1.5em;
    line-height: 36px;
  }
  .SecRight>div>p{
    font-size: 1em;
    line-height: 1.5em;
  }
  
}



