.sec7 {
  background-color: #11373e1c;
  height: 100vh;
  width: 100%;
  padding-block: 8.333vw;
  /* mix-blend-mode: color-burn; */
  font-family: 'Poppins';
  font-style: normal;
}

.SeventhPage {
  width: 80%;
  margin-inline: auto;
}

.SeventhPage>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.SeventhPage>div>.leftSide {
  padding-top: 20px;
  width: 50vw;
  /* justify-content: start; */
}

.SeventhPage>div>.leftSide>img {
  height: 100%;
  width: 80%;
}

.SeventhPage>div>.RightSide {
  /* padding: 20px; */
  width: 50vw;
}

.SeventhPage>div>.RightSide>ul>li {
  cursor: pointer;
}

.SeventhPage>div>.RightSide>h1 {
  font-size: 2em;
  padding: 20px 40px;
  /* width: 60%; */
}




@media only screen and (max-width: 768px) {
  .SeventhPage {
    width: 100%;
  }

  .sec7 {
    height: 100vh;
    width: 100%;
    padding-inline: 3.5rem;
  }

  .SeventhPage>.svPage {
    flex-direction: column;
    width: 100%;
    height: 100% !important;
  }

  .SeventhPage>div {
    width: 100%;
  }

  .SeventhPage>div>.leftSide {
    width: 100%;
    text-align: center;

  }

  .SeventhPage>div>.RightSide {
    /* padding: 20px; */
    width: 100%;
    text-align: center;

  }

  .SeventhPage>div>.RightSide>h1 {
    padding: 0;
    text-align: center;
  }

  .SeventhPage>div>.RightSide>ul {
    text-align: center;
    margin-inline: auto;
    justify-content: center;
  }

  .SeventhPage p {
    display: block;
    font-size: 1.25em !important;
  }
}

@media only screen and (max-width: 468px) {
  .sec7 {
    padding-inline: 1.75em;
  }

  .SeventhPage p {
    display: inline-block;
    font-size: 1em !important;
  }

  .sec7 .contact_us ul {
    flex-direction: column;
    justify-content: center;
    width: max-content;
    padding: 0px;
  }

  #contact_us {
    flex-direction: column !important;
    justify-content: center !important;
    width: max-content;
    padding: 0px !important;
  }

  #contact_us>div {
    width: 100% !important;
  }

  .SeventhPage>div>.RightSide>h1 {
    font-size: 1em;
    /* width: 60%; */
  }

  .SeventhPage>div>.RightSide>h1 {
    font-weight: 500;
    font-size: 1em;
    line-height: 24px;
  }

}